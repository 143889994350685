import * as React from "react";
import { BaseComponent } from "../../base/BaseComponent";
import { IndustriesGridComponent } from "./IndustriesGridComponent";
import Industry from "./Industry";
import { BlockchainTechnologies } from "./BlockchainTechnologies";

export class IndustriesComponent extends BaseComponent {
  render() {
    let key = -1;
    return (
      <div>
        <IndustriesGridComponent {...this.props} />

        <Industry
          params={{
            heading: this.lang.Blockchain,
            para1: this.lang.BlockchainPara1,
            para2: this.lang.BlockchainPara2,
            para3: this.lang.BlockchainPara3,
            imgSrc: "assets/images/coins.svg",
            bgColor: "bg-white",
            key: ++key
          }}
        >
          <BlockchainTechnologies />
        </Industry>
        <Industry
          params={{
            heading: this.lang.Healthcare,
            para1: this.lang.HealthcarePara1,
            para2: this.lang.HealthcarePara1,
            para3: this.lang.HealthcarePara3,
            imgSrc: "assets/images/care.svg",
            bgColor: "bg-dark",
            clientSrc: "assets/images/nhs.svg",
            key: ++key
          }}
        />
        <Industry
          params={{
            heading: this.lang.BankingAndFinance,
            para2: this.lang.BankingPara2,
            imgSrc: "assets/images/debit-card.svg",
            bgColor: "bg-white",
            key: ++key
          }}
        >
          {this.lang.BankingPara1}:&nbsp;
          <a href="https://bitvelocity.io" target="_blank" rel="noopener">
            BitVelocity
          </a>
          &nbsp;is a secure, real-time, social, crypto trading solution for
          digital assets.
        </Industry>

        <Industry
          params={{
            heading: this.lang.RealTimeApplications,
            para1: this.lang.RTTradingPara1,
            imgSrc: "assets/images/real-time.svg",
            bgColor: "bg-dark",
            clientSrc: "assets/images/bitvel.png",
            key: ++key
          }}
        />
        <Industry
          params={{
            heading: this.lang.Education,
            para1: this.lang.EducationPara1,
            para2: this.lang.TechnologiesUsed + ": C , C++, Qt, Windows API",
            imgSrc: "assets/images/brainstorm.svg",
            bgColor: "bg-white",
            clientSrc: "assets/images/netsupport.png",
            key: ++key
          }}
        />
        <Industry
          params={{
            heading: this.lang.Cloud + " " + this.lang.Technologies,
            para1: this.lang.CloudTechPara1,
            para2: this.lang.CloudTechPara2,
            para3:
              this.lang.TechnologiesUsed +
              ": " +
              this.lang.CPP +
              "," +
              this.lang.NodeJS +
              ", " +
              this.lang.ReactJS +
              ", " +
              this.lang.Linux +
              ", " +
              `Amazon EC2, JNI, TCP, UDP, NAT, Networking Programming,
            Client/Server Development, RTSP, RTP, Video Streaming, GPU
            Rendering`,
            imgSrc: "assets/images/cloud.svg",
            bgColor: "bg-dark",
            key: ++key
          }}
        />
        <Industry
          params={{
            heading: this.lang.Automotive + " " + this.lang.Technologies,
            para1: this.lang.AutomotivePara1,
            para2: this.lang.AutomotivePara2,
            para3: this.lang.TechnologiesUsed + ": Python, Linux, C++, Oracle",
            imgSrc: "assets/images/timing-belt.svg",
            bgColor: "bg-white",
            key: ++key
          }}
        />
        <Industry
          params={{
            heading: this.lang.TravelAndTourism,
            para1: this.lang.TravelPara1,
            para2: this.lang.TravelPara2,
            imgSrc: "assets/images/globe.svg",
            bgColor: "bg-dark",
            clientSrc: "assets/images/client-1.png",
            key: ++key
          }}
        />
        <Industry
          params={{
            heading: this.lang.Manufacturing,
            para1: this.lang.ManufacturePara1,
            para2: this.lang.ManufacturePara2,
            para3: this.lang.TechnologiesUsed + ": Python, Linux, C++, Oracle",
            imgSrc: "assets/images/factory.svg",
            bgColor: "bg-white",
            clientSrc: "/assets/images/renault.svg",
            key: ++key
          }}
        />
        <Industry
          params={{
            heading: this.lang.Intellectual + " " + this.lang.PropertyProducts,
            para1: this.lang.IPRPara1,
            para2: this.lang.IPRPara2,
            imgSrc: "assets/images/intellectual-property.svg",
            bgColor: "bg-dark",
            key: ++key
          }}
        >
          <a
            href="https://patents.google.com/patent/EP3325116A1/en"
            target="_blank"
            rel="noopener"
          >
            {this.lang.Patent1}
          </a>
          <br />
          <br />
          <p>
            {this.lang.IPRPara31}
            <a href="#contact-us">{this.lang.GetInTouch}</a>&nbsp;
            {this.lang.IPRPara32}
          </p>
        </Industry>
        <Industry
          params={{
            heading: this.lang.Gaming,
            para1: this.lang.GamingPara1,
            para2: this.lang.GamingPara2,
            para3:
              this.lang.TechnologiesUsed +
              ": " +
              "NodeJS, ReactJS, C++, Java, C#, AWS EC2, Cyber Security, Redis",
            imgSrc: "assets/images/game-controller.svg",
            bgColor: "bg-white",
            clientSrc: "assets/images/client-2.png",
            key: ++key
          }}
        />
        <Industry
          params={{
            heading: this.lang.MediaAnd + " " + this.lang.Entertainment,
            para1: this.lang.MediaPara1,
            para2: this.lang.MediaPara2,
            imgSrc: "assets/images/confetti.svg",
            bgColor: "bg-dark",
            clientSrc: "assets/images/bethesda1.png",
            key: ++key
          }}
        />
      </div>
    );
  }
}
