import * as React from "react";
import { BaseComponent } from "../base/BaseComponent";

export class ExperienceComponent extends BaseComponent {
  render() {
    return (
      <section className="exper-sec">
        <div className="container">
          <div className="row">
            <div className="col-lg-12">
              <ul>
                <li>
                  <div className="center">
                    15<em>+</em>
                  </div>
                  <i>
                    {(
                      this.lang.Years +
                      " " +
                      this.lang.Experienced
                    ).toUpperCase()}
                  </i>
                </li>
                <li>
                  <div className="center">
                    20<em>+</em>
                  </div>
                  <i>{this.lang.ProjectLaunched.toUpperCase()}</i>
                </li>
                <li>
                  <div className="center">
                    24<em>/7</em>
                  </div>
                  <i>{this.lang.Support.toUpperCase()}</i>
                </li>
                <li>
                  <div className="center">
                    5<em>+</em>
                  </div>
                  <i>{this.lang.SatisfiedCustomers.toUpperCase()}</i>
                </li>
              </ul>
            </div>
          </div>
        </div>
      </section>
    );
  }

  constructor(props) {
    super(props);
    this.init();
  }

  init() {}
}
