import * as React from "react";
import { BaseComponent } from "../base/BaseComponent";

export class TechnologiesComponent extends BaseComponent {
  render() {
    return (
      <section className="working-tech-sec">
        <div className="container">
          <div className="row">
            <div className="col-lg-12 text-center">
              <h2>
                {this.lang.TechnologiesPara}
                {/* <br />
                {this.lang.TechnologiesPara2} */}
              </h2>
              <ul className="tech-list">
                <li>
                  <div className="center">
                    <img src="assets/images/work-1.png" alt="" />
                  </div>
                  <i>{this.lang.NodeJS}</i>
                </li>
                <li>
                  <div className="center">
                    <img src="assets/images/work-2.png" alt="" />
                  </div>
                  <i>{this.lang.AngularJS}</i>
                </li>
                <li>
                  <div className="center">
                    <img src="assets/images/work-3.png" alt="" />
                  </div>
                  <i>{this.lang.ReactJS}</i>
                </li>
                <li>
                  <div className="center">
                    <img src="assets/images/work-4.png" alt="" />
                  </div>
                  <i>{this.lang.CPP}</i>
                </li>
                <li>
                  <div className="center">
                    <img src="assets/images/work-5.png" alt="" />
                  </div>
                  <i>{this.lang.AWS}</i>
                </li>
                <li>
                  <div className="center">
                    <img src="assets/images/work-6.png" alt="" />
                  </div>
                  <i>{this.lang.EC2Console}</i>
                </li>
                <li>
                  <div className="center">
                    <img src="assets/images/work-7.png" alt="" />
                  </div>
                  <i>{this.lang.ERC20Tokens}</i>
                </li>
                <li>
                  <div className="center">
                    <img src="assets/images/work-8.png" alt="" />
                  </div>
                  <i>{this.lang.DApps}</i>
                </li>
                <li>
                  <div className="center">
                    <img src="assets/images/work-9.png" alt="" />
                  </div>
                  <i>{this.lang.HTML5}</i>
                </li>
                <li>
                  <div className="center">
                    <img src="assets/images/work-10.png" alt="" />
                  </div>
                  <i>{this.lang.XenServer}</i>
                </li>
                <li>
                  <div className="center">
                    <img src="assets/images/work-15.png" alt="" />
                  </div>
                  <i>{this.lang.Blockchain}</i>
                </li>

                <li>
                  <div className="center">
                    <img src="assets/images/work-11.png" alt="" />
                  </div>
                  <i>{this.lang.CloudVirtualization}</i>
                </li>
                <li>
                  <div className="center">
                    <img src="assets/images/work-14.png" alt="" />
                  </div>
                  <i>{this.lang.ApacheTomcat}</i>
                </li>
                <li>
                  <div className="center">
                    <img src="assets/images/work-12.png" alt="" />
                  </div>
                  <i>{this.lang.RTApps}</i>
                </li>
                <li>
                  <div className="center">
                    <img src="assets/images/work-13.png" alt="" />
                  </div>
                  <i>{this.lang.Java}</i>
                </li>
              </ul>
            </div>
          </div>
        </div>
      </section>
    );
  }

  constructor(props) {
    super(props);
    this.init();
  }

  init() {}
}
