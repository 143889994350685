import { Constants } from "../shared/constants";
import { LanguageBase } from "./language";

export class LangENUS implements LanguageBase {
  English = "English";
  German = "German";
  Name = "Name";
  Email = "Email";
  ContactNo = "Contact Number";
  Message = "Message";
  Submit = "Submit";
  InvalidEmail = "Invalid " + this.Email;
  MaxLengthFormat = "Maximum {0} character(s)";
  MaxLengthFormat2 = "{0} accept maximum {1} character(s)";
  RangeLengthFormat = "Minimum {0} and Maximum {1} character(s)";
  RangeLengthFormat2 = "{0} accept minimum {1} and Maximum {2} character(s)";
  RequiredFormat = "{0} is required";
  ErrorOccurred = "An error occurred";
  ErrorOccurredWhileValidationFormat = "An error occurred while validating {0}";
  ErrorOccurredFormat = "An error occurred {0}";
  UUID = "UUID";
  UUIDNotProvided = this.UUID + " not provided";
  AlreadyExistsFormat = "{0} already exists";
  SomethingWentWrongTryAgainLater =
    "Something went wrong, please try again later";
  ContactUsInsertedResponseMessage =
    "Thank you for contacting us. We've received your message and we'll get back to you soon.";
  UUIDAlreadyExists = "UUID already exists";
  First = "First";
  Last = "Last";
  FirstName = this.First + " " + this.Name;
  LastName = this.Last + " " + this.Name;
  User = "User";
  UserName = this.User + " " + this.Name;
  AlphaNumericFormat = "{0} must be alpha numeric";
  MustContainOneSpecialCharFormat =
    "{0} must contain at least one special character";
  MustContainOneCapitalLetterFormat =
    "{0} must contain at least one capital letter";
  MustContainOneSmallLetterFormat =
    "{0} must contain at least one small letter";
  MustContainOneNumberFormat = "{0} must contain at least one number";
  PasswordRequirement = [
    this.RangeLengthFormat2,
    this.MustContainOneCapitalLetterFormat,
    this.MustContainOneSmallLetterFormat,
    this.MustContainOneSpecialCharFormat,
    this.MustContainOneNumberFormat
  ];
  Password = "Password";
  Confirm = "Confirm";
  ConfirmPassword = this.Confirm + " " + this.Password;
  DidNotMatchFormat = "{0} and {1} didn't match";
  CaptchaErrorMessage = "Help us prevent spam. Please click on the checkbox";
  Company = "Company";
  CompanyName = this.Company + " " + this.Name;
  CannotStartWithNumberFormat = "{0} Can't start with a number";
  UserNameRequirement = [
    this.RangeLengthFormat2,
    this.AlphaNumericFormat,
    this.CannotStartWithNumberFormat
  ];
  UserNameNotAvailable = this.UserName + " not available";
  EmailAlreadyRegistered =
    "An account with this " +
    this.Email +
    " already exists. Please click login to continue with that account or enter another " +
    this.Email;
  AccountType = "Account Type";
  InvalidValueFormat = "Invalid value for {0}";
  VerificationEmailSent = "We've Sent You a Verification Email";
  VerificationEmailSentDetail = `Click the link in your email to verify your account
If you didn't find the email, please check your spam folder or click the link
below to re-send`;
  Resend = "Resend";
  ResendEmail = this.Resend + " " + this.Email;
  CouldNotVerifyClient = "Could not verify client";
  UnAuthorizedRequest = "UnAuthorized Request";
  SignUpVerificationNotPending =
    "Sign Up verification is not pending for current user";
  Success = "Success";
  CouldNotSendEmailNow = "Couldn't send email now. Please try again later";
  UnableToCompleteYourRequest =
    "We cannot complete your request this time. Please try again later. If the problem persist, please let us inform through contact us";
  AccountCreatedLoginToContinue =
    "Your account is created. Please login to continue. Redirecting you to login ...";
  Again = "again";
  VerificationEmailSentAgain = this.VerificationEmailSent + " " + this.Again;
  Verifying = "Verifying ...";
  Forgot = "Forgot";
  ForgotPassword = this.Forgot + " " + this.Password;
  BitVelocity = "BitVelocity";
  NotJoinedBitvelocityYet = "Not joined " + this.BitVelocity + " yet?";
  Join = "Join";
  Now = "Now";
  JoinNow = this.Join + " " + this.Now;
  Login = "Login";
  Credentials = "Credentials";
  AlreadyHaveAnAccount = "Already have a " + this.BitVelocity + " Account?";
  EmailVerificationRequired =
    "Email not verified. Please check your mail box. If it isn't in your inbox, check your Spam, Junk, Trash, Deleted Items, or Archive folder or click {0} to resend.";
  Here = "here";
  VerificationKey = "Verification Key";
  VerificationKeyExpired =
    this.VerificationKey +
    " is expired. A " +
    this.VerificationKey +
    " is expired when a user is already verified or when you request a new verification email.";
  CouldNotVerifyUser = "Sorry! could not verify this " + this.User;
  UserAlreadyVerified = "This user is already verified";
  RedirectingYouToLogin =
    "Please Login your account to continue. Redirecting to Login";
  SuccessfullyVerifiedUser =
    "Verification completed with success. " + this.RedirectingYouToLogin;
  NotExists = "Not Exists";
  NotExistsFormat = "{0} " + this.NotExists;
  Invalid = "Invalid";
  InvalidFormat = this.Invalid + " {0}";
  Home = "Home";
  Blockchain = "Blockchain";
  Travel = "Travel";
  RealEstate = "Real Estate";
  Charts = "Charts";
  FAQ = "FAQ";
  AboutUs = "About Us";
  ContactUs = "Contact Us";
  Help = "Help";
  SignUp = "Sign Up";
  Wallet = "Wallet";
  Logout = "Logout";
  CouldNotLoadConfig = "Could Not Load Config";
  Limit = "Limit";
  Sell = "Sell";
  Buy = "Buy";
  Price = "Price";
  Amount = "Amount";
  Total = "Total";
  Action = "Action";
  Currency = "Currency";
  Pair = "Pair";
  CurrencyPair = this.Currency + " " + this.Pair;
  Market = "Market";
  AvailableBalance = "Available Balance";
  Gross = "Gross";
  GrossTotal = this.Gross + " " + this.Total;
  Fee = "Fee";
  Date = "Date";
  Type = "Type";
  NonVerifiedUserCannotPlaceOrder = "Non verified user cannot place the order";
  ThisUserCannotPlaceOrder = "This user cannot place the order";
  NotFound = "not found";
  NotFoundFormat = "{0} not found";
  Min = "Minimum";
  Order = "Order";
  MinTotalOrderAmountFormat =
    this.Min +
    " " +
    this.Total +
    " " +
    this.Order +
    " " +
    this.Amount +
    " is {0} {1}";
  NotSupportFormat = "{0} not supported";
  NotEnoughBalance = "Not enough balance available";
  MinTierRequriedForTransaction =
    "Minimum tier {0} is required for a transaction";
  NoRecordFound = "No record found";
  OrderPlacedSuccessfully = "Order placed successfully";
  LangKey = Constants.Instance.LanguageKey.ENUS;
  CouldNotBeginTransaction = "Could not begin trnsaction";
  CouldNotCompleteTransaction = "Could not complete transaction";
  CouldNotExecuteQuery = "Could not execute Query";
  OrderCompleted = "Order Completed";
  UserWalletNotFoundForOneOfThePairCurrencies =
    "User wallet for one of the pair currencies was not found";
  CouldNotGetResponseFromKraken = "Could not get response from kraken";
  PairKrakenMinOrderVolumeNotDefined =
    "Kraken minimum order volume not defined for this pair";
  OrderLessThanMinKrakenOrderVolume =
    "Order amount is less than kraken minimum order volume";
  PairMappingWithKraken = "pair mapping with kraken";
  UnableToVerifyFormat = "Unable to verify {0}";
  Request = "Request";
  OrderStatusMustBeOpenOrPartiallyCompleted =
    "Order status must be open or partially completed";
  Status = "Status";
  Trade = "Trade";
  UnableToCreateQueueTaskForUpdatingExchangeOrigin =
    "Unable to create queue task from updating exchange origin";
  TaskId = "Task Id";
  UnableToUpdateExchangeOrigin = "Unable to update exchange origin to {0}";
  OrderPostedOnKrakenWithoutTxId = "Order posted on kraken without txid";
  OrderPostedOnThirdParty = "Order posted on {0} Successfully";
  OrderPostedOnThirdPartyButCouldNotItsTxid =
    this.OrderPostedOnThirdParty + " but could not save its txid";
  OrderAlreadyPostedOnFormat = "Order already on {0}";
  ExternalOrderIdNotFound = "Order Id for {0} not found";
  ChangeExchangeManually = "Please change order exchange origin manually";
  CaptchaNotVerified = "Captcha not verified. Please submit the form again";
  FieldRequired = "This Field is required";
  Skype = "Skype";
  Id = "Id";
  SkypeId = this.Skype + " " + this.Id;
  Budget = "Budget";
  Consulting = "Consulting";
  Cost = "Cost";
  You = "You";
  Get = "Get";
  YouGet = this.You + " " + this.Get;
  NotAnAdminUser = "Not an amdin user";
  OrderCancellationResponseFromThirdParty =
    "Order cancellation resposne from {0} is {1}";
  UnableToSaveFormat = "Unable to save {0}";
  Session = "Session";
  Volume = "Volume";
  LastPrice = this.Last + " " + this.Price;
  Change = "Change";
  High = "High";
  Low = "Low";
  Page = "Page";
  Back = "Back";
  BackToHome = this.Back + " to " + this.Home;
  Deposit = "Deposit";
  Withdraw = "Withdraw";
  Withdrawl = "Withdrawl";
  SelectCurrencyTodeposit = "Select " + this.Currency + " to " + this.Deposit;
  Balance = "Balance";
  TotalBalance = this.Total + " " + this.Balance;
  OnHold = "On Hold";
  Account = "Account";
  AccountName = this.Account + " " + this.Name;
  Number = "Number";
  AccountNumber = this.Account + " " + this.Number;
  Bank = "Bank";
  BankName = this.Bank + " " + this.Name;
  Branch = "Branch";
  IBAN = "IBAN";
  Address = "Address";
  Minimum = "Minumum";
  WhichEverHigherFormat = "{0} or {1} (which ever is higher)";
  Reference = "Reference";
  Sr = "Sr.";
  DepositRequest = this.Deposit + " " + this.Request;
  DepositDate = this.Deposit + " " + this.Date;
  New = "New";
  NoCurrencySelected = "No " + this.Currency + " selected";
  Cancel = "Cancel";
  CreatedSuccessfullyFormat = "{0} created successfully";
  Select = "Select";
  File = "File";
  SelectFile = this.Select + " " + this.File;
  Clear = "Clear";
  Size = "Size";
  SizeLimit = this.Size + " " + this.Limit;
  Exceeds = "Exceeds";
  SizeLimitExceeds = this.SizeLimit + " " + this.Exceeds;
  AllowFileTypesAreFormat = "Allowed file types are {0}";
  filesSelectedFormat = "{0} files selected";
  Funding = "Funding";
  Free = "Free";
  to = "to";
  Individual = "Individual";
  Business = "Business";
  Create = "Create";
  CreateAccount = this.Create + " " + this.Account;
  Receipt = "Receipt";
  DepositReceipt = this.Deposit + " " + this.Receipt;
  InterestedIn = "Interested In";
  Duration = "Duration";
  FreeInitialConsultation = "Free Initial Consultation";
  AreYouLookingForAReliable = "Are you looking for a reliable, experienced";
  SolutionProviderThatCanDeliverInTime =
    " solution provider to deliver your next project in time?";
  Transparency = "Transparency";
  Quality = "Quality";
  Deliverables = "Deliverables";
  QualityDeliverables = this.Quality + " " + this.Deliverables;
  Flexible = "Flexible";
  Engagement = "Engagement";
  Models = "Models";
  FlexibleEngagementModels =
    this.Flexible + " " + this.Engagement + " " + this.Models;
  Skilled = "Skilled";
  Team = "Team";
  SkilledTeam = this.Skilled + " " + this.Team;
  Talk = "Talk";
  Our = "Our";
  Experts = "Experts";
  TalkToOurExperts = this.Talk + " to " + this.Our + " " + this.Experts;
  Meet = "Meet";
  Founder = "Founder";
  CEO = "CEO";
  MeetFounderAndCEO = this.Meet + " " + this.Founder + " and " + this.CEO;
  SerialEnterpreneurPara =
    "Serial entrepreneur and technology evangelist with expertise in real-time applications,";
  CPP = "C++";
  CSharp = "C#";
  Linux = "Linux";
  NodeJS = "NodeJS";
  ReactJS = "ReactJS";
  InnovateSolutionsPara =
    "We believe in innovative solutions using modern technologies to solve real world problems for humans";
  Years = "Year(s)";
  Highly = "Highly";
  Experienced = "Experienced";
  HighlyExperienced = this.Highly + " " + this.Experienced;
  Reliable = "Reliable";
  Trustable = "Trustable";
  ReliableAndTrustable = this.Reliable + " & " + this.Trustable;
  Successful = "Successful";
  Cooperation = "Cooperation";
  SuccessfulCooperation = this.Successful + " " + this.Cooperation;
  HowItWorks = "How We Work";
  TalkToOneofOurPara = "Talk to one of our";
  IndustryExpertsPara = "industry experts";
  AVelocityDirectorPara = "A Velocity director of engineering will";
  AVelocityDirectorPara2 = "then asses the workload and select";
  AVelocityDirectorPara3 = "suitable resource for the project.";
  AllocateSuitableResource = "Allocate suitable resource";
  AllocatedResourcesInitiates = `allocated resources initiates the meetings with the clients to understand the project requirements and devise agreed 
    project plan`;
  EnsuringPromptDelivery = "Ensuring prompt delivery";
  EnsuringPromptDeliveryPara1 = "Development starts under internal Scrum";
  EnsuringPromptDeliveryPara2 = "Master and partners are updated weekly";
  EnsuringPromptDeliveryPara3 = " on progress";
  ServicesWeDeliver = "Services we deliver";
  ServicesWeDeliverPara = `At Velocity solutions we are passionate about building new generation applications
    using technology. Since we are building solutions that will solve real-world
    problem. we would love to be a part of organization working in same industry.`;
  TechnicalConsulting = "Technical Consulting";
  TechnicalConsultingPara = `Our in-house quality control analysts ensure that product only goes live
    in
    production once it passes all stress testing techniques minimizing last
    minute production fixes`;
  ProductDevelopment = "Product Development";
  ProductDevelopmentPara = `We have helped our clients improve their existing product ensuring
    reliability and capability.`;
  UIAndUX = "UI & UX";
  UIAndUXPara = `Our in-house quality control analysts ensure that product only goes live
    in
    production once it passes all stress testing techniques minimizing last
    minute production fixes`;
  DevOps = "Dev Ops";
  DevOpsPara = `Our in-house quality control analysts ensure that product only goes live
    in
    production once it passes all stress testing techniques minimizing last
    minute production fixes`;
  TechnologiesPara = "Technologies We Specialize In";
  TechnologiesPara2 =
    "contact us today, our experts will be in touch with you within 3 working days";
  Experience = "Experience";
  Project = "Project";
  Launched = "Launched";
  ProjectLaunched = this.Project + " " + this.Launched;
  Support = "Support";
  Satisfied = "Satisfied";
  Customers = "Customers";
  SatisfiedCustomers = this.Satisfied + " " + this.Customers;
  OurSoftwareDevelopmentIndustries = "Our Software Development Industries";
  OurSoftwareDevelopmentIndustriesPara =
    "We have successfully delivered solutions in following industries";
  BlockchainPara = `We believe blockchain technology is next version of internet where trustless
    decentralized ecosystem will solve many problems on modern age. Based on
    this believe we are also developing our in-house products that will solve
    high fees exchange challenge that travelers have to have.
    Similarly using blockchain we are working to solve real-estate transaction
    issues by placing all the data of a real-estate transaction onto
    blockchain. Additionally, using this platform users are able to buy/sell
    real-estate using digital assets and our exchange system will act as a
    liquidity provider to ensure on-demand conversion is done by taking the
    hassle out of parties involved in the transaction.
    Weather it’s based on Ethereum blockchain, Solidity, private or public
    blockchain, Hyperledger, If you a project that is solving a real-world
    problem using blockchain technology we would be very much interested to
    work together delivering a reliable and scalable solution.`;

  BlockchainPara1 = `We believe blockchain technology is the next version of internet where 
  trustless decentralized ecosystem will solve many problems of modern age. Based on this 
  believe we are developing our in-house products that will solve high fee forex exchange 
  problem for travellers around the world.`;

  BlockchainPara2 = `We are working to solve real-estate transaction issues by placing all the data of a real-estate transaction onto blockchain.
  Additionally, using this platform, users are able to buy/sell real-estate using digital assets, and our exchange system will act as a liquidity provider
  to ensure on-demand conversion is done by taking the hassle out of parties involved in the transaction.`;

  BlockchainPara3 = `
  Whether it’s based on Ethereum blockchain, Solidity, private/public blockchain, 
  or Hyperledger, if you would like to develope a product which is scalable using blockchain 
  technology to solve real-world problems, we would be very much interested to work together in 
  delivering a reliable and a scalable solution.`;

  // -------------------------------------------------------- //
  BankingPara1 = `Velocity Solution has successfully built in-house product offering digital asset 
  exchange`;

  BankingPara2 = `Security of this blockchain based solution is designed to follow protocols of 
  running a bank, ensuring customer asset protection. We have also integrated different payment gateways, 
  being involved in designing and automation payment process for users.
  
  With BitVelocity, we are also working on blockchain based security tokens.`;

  // -------------------------------------------------------- //

  Healthcare = "Healthcare";
  HealthcarePara = `Our developers have dived into several projects in the Healthcare sector;
    ranging from Doctor-client appointment & booking systems to medicine
    verification scanners. If you are looking for a mobile app development
    company
    for your next big app in the healthcare industry, feel free to discuss your
    project with us.Our developers have dived into several projects in the
    healthcare sector; ranging from Doctor-client booking systems to medicine
    verification scanners.Our developers have dived into several projects in
    the
    Healthcare sector; ranging from Doctor-client appointment & booking systems
    to
    medicine verification scanners. If you are looking for a mobile app
    development
    company for your next big app in the healthcare industry, feel free to
    discuss
    your project with us.`;
  HealthcarePara1 = `Velocity Solutions is passionate about delivering successful systems for Healthcare sector. 
  We have worked on wide range of solutions  from "Doctor-Client appointment" booking systems to "Medicine Verification scanners".`;
  HealthcarePara2 = `Our team has experience in delivering most usable platforms involving browser based solutions, mobile platforms, cloud hosting, cloud software and cloud support. `;
  HealthcarePara3 = `Our team of specialized experts have worked directly with NHS Doctors involving consultants, registrars, 
  Senior House Officers and staff grade Nurses to not only understand the processes and data flows but also have successfully 
  innovated by becoming part of the team that can make a difference in healthcare sector.`;
  OurClients = "Our Clients";
  BankingAndFinance = "Banking & Finance";
  BankingAndFinancePara = `Our developers have dived into several projects in the Healthcare sector;
    ranging from Doctor-client appointment & booking systems to medicine
    verification scanners. If you are looking for a mobile app development
    company
    for your next big app in the healthcare industry, feel free to discuss your
    project with us.Our developers have dived into several projects in the
    healthcare sector; ranging from Doctor-client booking systems to medicine
    verification scanners. Our developers have dived into several projects in
    the healthcare sector; ranging from doctor-client appointment & booking systems
    to medicine verification scanners. If you are looking for a mobile app
    development company for your next big app in the healthcare industry, feel free to
    discuss your project with us.`;
  RealTimeTradingApps1 = "Real-time";
  RealTimeTradingApps2 = "Trading apps";
  RealTimeTradingAppsPara = `Our developers have dived into several projects in the Healthcare sector;
    ranging from Doctor-client appointment & booking systems to medicine
    verification scanners. If you are looking for a mobile app development
    company
    for your next big app in the healthcare industry, feel free to discuss your
    project with us.Our developers have dived into several projects in the
    healthcare sector; ranging from Doctor-client booking systems to medicine
    verification scanners.Our developers have dived into several projects in
    the
    Healthcare sector; ranging from Doctor-client appointment & booking systems
    to
    medicine verification scanners. If you are looking for a mobile app
    development
    company for your next big app in the healthcare industry, feel free to
    discuss
    your project with us.`;
  Education = "Education";
  EducationAndELearning1 = this.Education + " &";
  ELearning = "E-learning";
  EducationAndELearningPara = `Our developers have dived into several projects in the Healthcare sector;
    ranging from Doctor-client appointment & booking systems to medicine
    verification scanners. If you are looking for a mobile app development
    company
    for your next big app in the healthcare industry, feel free to discuss your
    project with us.Our developers have dived into several projects in the
    healthcare sector; ranging from Doctor-client booking systems to medicine
    verification scanners.Our developers have dived into several projects in
    the
    Healthcare sector; ranging from Doctor-client appointment & booking systems
    to
    medicine verification scanners. If you are looking for a mobile app
    development
    company for your next big app in the healthcare industry, feel free to
    discuss
    your project with us.`;
  Cloud = "Cloud";
  Technologies = "Technologies";
  TechnologiesWeSpecializeIn = "Technologies We Specialize In";
  CloudTechnologiesPara = `Our developers have dived into several projects in the Healthcare sector;
    ranging from Doctor-client appointment & booking systems to medicine
    verification scanners. If you are looking for a mobile app development
    company
    for your next big app in the healthcare industry, feel free to discuss your
    project with us.Our developers have dived into several projects in the
    healthcare sector; ranging from Doctor-client booking systems to medicine
    verification scanners.Our developers have dived into several projects in
    the
    Healthcare sector; ranging from Doctor-client appointment & booking systems
    to
    medicine verification scanners. If you are looking for a mobile app
    development
    company for your next big app in the healthcare industry, feel free to
    discuss
    your project with us.`;
  Automotive = "Automotive";
  AutomotivePara = `Our developers have dived into several projects in the Healthcare sector;
    ranging from Doctor-client appointment & booking systems to medicine
    verification scanners. If you are looking for a mobile app development
    company
    for your next big app in the healthcare industry, feel free to discuss your
    project with us.Our developers have dived into several projects in the
    healthcare sector; ranging from Doctor-client booking systems to medicine
    verification scanners.Our developers have dived into several projects in
    the
    Healthcare sector; ranging from Doctor-client appointment & booking systems
    to
    medicine verification scanners. If you are looking for a mobile app
    development
    company for your next big app in the healthcare industry, feel free to
    discuss
    your project with us.`;
  Tourism = "Tourism";
  TravelAndTourism = this.Travel + " & " + this.Tourism;
  TravelAndTourismPara = `Our developers have dived into several projects in the Healthcare sector;
    ranging from Doctor-client appointment & booking systems to medicine
    verification scanners. If you are looking for a mobile app development
    company
    for your next big app in the healthcare industry, feel free to discuss your
    project with us.Our developers have dived into several projects in the
    healthcare sector; ranging from Doctor-client booking systems to medicine
    verification scanners.Our developers have dived into several projects in
    the
    Healthcare sector; ranging from Doctor-client appointment & booking systems
    to
    medicine verification scanners. If you are looking for a mobile app
    development
    company for your next big app in the healthcare industry, feel free to
    discuss
    your project with us.`;
  Manufacturing = "Manufacturing";
  ManufacturingPara = `Our developers have dived into several projects in the Healthcare sector;
    ranging from Doctor-client appointment & booking systems to medicine
    verification scanners. If you are looking for a mobile app development
    company
    for your next big app in the healthcare industry, feel free to discuss your
    project with us.Our developers have dived into several projects in the
    healthcare sector; ranging from Doctor-client booking systems to medicine
    verification scanners.Our developers have dived into several projects in
    the
    Healthcare sector; ranging from Doctor-client appointment & booking systems
    to
    medicine verification scanners. If you are looking for a mobile app
    development
    company for your next big app in the healthcare industry, feel free to
    discuss
    your project with us.`;
  Intellectual = "Intellectual";
  Property = "Property";
  Products = "Products";
  PropertyProducts = this.Property + " " + this.Products;
  IntellectualPropertyProductsPara = `Our developers have dived into several projects in the Healthcare sector;
    ranging from Doctor-client appointment & booking systems to medicine
    verification scanners. If you are looking for a mobile app development
    company
    for your next big app in the healthcare industry, feel free to discuss your
    project with us.Our developers have dived into several projects in the
    healthcare sector; ranging from doctor-client booking systems to medicine
    verification scanners.Our developers have dived into several projects in
    the
    Healthcare sector; ranging from Doctor-client appointment & booking systems
    to
    medicine verification scanners. If you are looking for a mobile app
    development
    company for your next big app in the healthcare industry, feel free to
    discuss
    your project with us.`;
  Gaming = "Gaming";
  GamingPara = `Our developers have dived into several projects in the Healthcare sector;
    ranging from Doctor-client appointment & booking systems to medicine
    verification scanners. If you are looking for a mobile app development
    company for your next big app in the healthcare industry, feel free to discuss your
    project with us.Our developers have dived into several projects in the
    healthcare sector; ranging from Doctor-client booking systems to medicine
    verification scanners.Our developers have dived into several projects in
    the Healthcare sector; ranging from Doctor-client appointment & booking systems
    to medicine verification scanners. If you are looking for a mobile app
    development company for your next big app in the healthcare industry, feel free to
    discuss your project with us.`;
  Media = "Media";
  MediaAnd = this.Media + " &";
  Entertainment = "Entertainment";
  MediaAndEntertainmentPara = `Our developers have dived into several projects in the Healthcare sector;
    ranging from Doctor-client appointment & booking systems to medicine
    verification scanners. If you are looking for a mobile app development
    company for your next big app in the healthcare industry, feel free to discuss your
    project with us.Our developers have dived into several projects in the
    healthcare sector; ranging from Doctor-client booking systems to medicine
    verification scanners.Our developers have dived into several projects in
    the Healthcare sector; ranging from Doctor-client appointment & booking systems
    to medicine verification scanners. If you are looking for a mobile app
    development company for your next big app in the healthcare industry, feel free to
    discuss your project with us.`;
  ProjectsSuccessfullyDelivery1 = "Projects successfully delivered";
  ProjectsSuccessfullyDelivery2 = "for these clients";
  DepartureControlSystem1 = "Departure Control System and";
  DepartureControlSystem2 = "Flight management System";
  RealtimeRemoteControl1 = "Real-time remote control";
  RealtimeRemoteControl2 = "application";
  LondonUndergroundRailSimulation1 = "London underground rail";
  LondonUndergroundRailSimulation2 = "simulation";
  CloudGaming = this.Cloud + " " + this.Gaming;
  Virtualization = "Virtualization";
  CloudVirtualization = this.Cloud + " " + this.Virtualization;
  Lets = "Lets";
  ContactUs1 = "We believe in solving problems with";
  ContactUs2 = "Skill, Passion, Quality and Reliability";
  ContactUs3 = "that could result in building long term relationships.";
  ContactUs4 = "So, if you are looking for a developer with these qualities, ";
  ContactUs5 =
    "fill the form below to get free initial consultation, and our team will be in contact within few days.";
  ThankYouForContactingUs = "Thank you for contacting us.";
  WeHaveReceivedYourMessage = "We've received your message.";
  OurExpertWillBeInTouchWithYou =
    "Our experts will be in touch with you within 3 working days";
  FahadSheikh = "Fahad Sheikh";
  AngularJS = "AngularJS";
  MeanStack = "Mean Stack";
  ReactNative = "React Native";
  BlockchainForTravel = this.Blockchain + " for " + this.Travel;
  BlockchainForRealEstate = this.Blockchain + " for " + this.RealEstate;
  HTML5 = "HTML5";
  CSS = "CSS";
  FullStackDevelopment = "Full Stack Development";
  MobileDevelopment = "Mobile Development";
  ServerDevelopment = "Server Development";
  AWS = "AWS";
  EC2Console = "EC2 Console";
  ERC20Tokens = "ERC20 Tokens";
  DecentralisedApps = "Decentralised Apps";
  DApps = "DApps";
  RTApps = "RT Apps";
  XenServer = "Xen Server";
  RealTime = "Real Time";
  Applications = "Applications";
  RealTimeApplications = this.RealTime + " " + this.Applications;
  Java = "Java";
  ApacheTomcat = "Apache Tomcat";
  Copyrights = "Copyrights";
  CopyrightsWithYear = this.Copyrights + " @ 2019";
  AllRightsReserved = "All rights reserved";
  VelocitySolutions = "Velocity Solutions";
  OperatingUnderVelocitySolutions = "Operating under " + this.VelocitySolutions;
  CompanyNo = "company no";
  Method = "Method";
  DepositMethod = this.Deposit + " " + this.Method;
  Params = "Params";
  MinimumAmountFormat = this.Minimum + " " + this.Amount + " is {0}";
  NotSaved = "Not Saved";
  OurTeam = "Our Team";

  Inaam = "Inaam ur Rehman";
  Shoaib = "Shoaib Khalid";
  Jamil = "Jamil Farooq";

  Development = "Development";
  Senior = "Senior";
  Junior = "Junior";
  TeamDescription1 = `Senior Javascript Server Developer who likes to work on the whole spectrum of development. 
  Working as a core team member at BitVelocity, he delivers scalable, secure and reliable solutions for our platform.
  Inaam finds interesting ways of online payments for crypto, and wants to see cryptos into mainstream.`;
  TeamDescription2 = `Junior Full Stack Developer who, before joining the BitVelocity team, developed smart contracts for Etherem Network. 
                      He loves to work with modern technologies and tools to provide solutions that are effective and reliable.`;

  TeamDescription3 = `Senior member of the Velocity Solutions family who has expertise and experiences across 
  the range of technologies and platforms. In the past he has developed solutions for engineering as well 
  as business. Few areas where he has worked previously are simulations, telecom, aerospace, high frequency
  trading, and cloud solutions: streaming as a service and cloud infrastructure.
  He has broad set of skills as technology agnostic developer and has built solutions in multiple platforms 
  such as Linux and Windows and languages such as Node.JS, React.JS, Java and C++.`;

  Solidity = "Solidity";

  SecurityTokens = "Security Tokens";
  DigitalAssetExchange = "Digital Asset Exchange";

  Lead = `Lead`;
  David = "David";
  Watkin = "Watkin";

  Core = "Core";

  CEOPara = `Fahad Sheikh is a technology evangelist and a serial entrepreneur who has built
  successful startups previously, has run 3 development studios in 3 different
  cities at a given time, an investor, a technology consultant and someone who
  believes in solving real world problems using technology.`;

  WatkinsPara = `David has proven track record of leading successful teams using agile methodologies for fortune 500 companies. 
  He believes in saving cost for development by focusing on quality of development instead of quantity. `;

  TeamPara = `Our team is a group of people who are working day in, day out to make a
  product that lives up to not only the expectations of its users, but also to
  their own expectations. It's a diverse group of people from different
  backgrounds and areas of expertise, recruited by the founder of the company to
  work in tandem to build his promising platform in the light of his vision.`;

  CloudTechPara1 = `We specialize in Cloud services including cloud software, services and support. 
  We are also approved supplier for Digital Market Place, offering following services`;

  CloudTechPara2 = `At Velocity Solutions we build an innovative cloud gaming technology for Europe's 3rd largets game publisher Gamigo.
  Here are come of the achievements for one of our biggest project delivered.`;

  EducationPara1 = `We have closely worked with innovators in education sector. We have worked 
  on remote control software for schools where team can monitor and control pupils computer including 
  their applications using management software called NetSupport School. We helped NetSupport 
  modernize and develop windows version of their product to be available for Linux and Mac Operating systems.`;

  EducationPara2 = `We have also developed and completed Networking Notification solution called 
  NetSupport Notify that can take control of students' computer to ensure they read the messages sent 
  by their teacher and send acknowledgement back. NetSupport Notify was supported across 11 different 
  Linux distributions`;

  AutomotivePara1 = `We have worked in one of the target car manufacturing client Renault for application
  modernization. The legacy application was built in old and obsolete OPENVMS technologies with no 
  future support offered by hardware manufactures.`;

  AutomotivePara2 = `We have played an active part in collaboration with other Suppliers to delivery the modernization project
  of Upgrading both Database from flat files to ORACLE and old legacy code to modernize into latest
  C++ and Linux operating system.`;

  RTTradingPara1 = `We have developed a real-time trading app: BitVelocity, it's our in-house product.
  BitVelocity is a secure, real-time, social, crypto trading solution for digital assets. BitTrade will be our 
  the first commercial product targeted to be launched by Q2 2019 in production. Based on our smart 
  proprietary trading system using Artificial Intelligence to analyze critical data and predict market 
  trends.`;

  TravelPara1 = `Amadues is the largest player by transaction volume and revenues in travel in whole Europe. 
  In 2018 Amadeus generated revenues of €5billion. Most European Airline bookings are done through Amadeus systems.`;

  TravelPara2 = `We have worked with our client Amadues to add new features under Departure Control 
  System and Flight Management System for British Airways, Lufthansa Airway and Saudi Airlines.`;

  IPRPara1 = `Our Founder has successfully invented a patented technology in a Cloud Gaming space.
  This technology was invented in 2015 is similar  technology that Google is trying to launch in 2019 with Google Stadia.`;
  IPRPara2 = `We have successfully filed software patents for our invention under European Patent Office as well as under 
  US Patent Law.`;

  IPRPara31 = `Feel Free to `;
  GetInTouch = `get in touch`;
  IPRPara32 = `with us for a Product development that involves innovation, 
  needs Non Disclosure Agreement (NDA) signed or needs to be protected via Patents, even if you need 
  help in filing patents in EU, US or Asia.`;

  Patent1 = `PCT/EP2015/002585, European Patent Office Patent No:
  15891030.7 - 1209`;

  GamingPara1 = `Velocity Solutions not only has keen interest in Gaming. In collaboration with 
  Gamigo, we are one of the innovators in delivering patented Cloud Gaming Technology that enabled
  users to play a AAA Title in few seconds without download and at HD quality. 
  Technology delivered was able to offer 60 fps with 1080p resolution.`;

  GamingPara2 = `Our hardware encoding technology assured low latency of up to 50ms (end to end) to 
  gamers on cloud hence enabling them to play game on the fly in browser, windows
  desktop , android and IOS Platform.`;

  GamingPara3 = `We also have experience working with UnReal Engine while working on Fiesta NA 
  game for Gamigo. Additionally we can also supply specialized gaming developers 
  who can develop games in Unity3D.`;

  MediaPara1 = `We innovated in Gaming industry where gamers had to wait for 4 to 5 hours before
  they can try a AAA game title. Our streaming solution solved that problem.
  We delivered game streaming solutions for gamers who would like to first
  try the game before they would decide to download it.`;

  MediaPara2 = `We worked with a very large game publisher, Bethesda. Our work in collaboration
  with Bethesda delivered one of their most popular game "Elder Scrolls Online"—delivered
  on IBM cloud with streaming. This launch helped users to try new contents of the game 
  without needing to worry about downloading.`;

  ManufacturePara1 = ` We have collaborated with one of the largest car manufacturing client, 
  Renault for modernization of their application. Their legacy application was built in 
  OpenVMS technologies with no future support offered by hardware manufactures.`;

  ManufacturePara2 = `We played an active part in collaboration with other Suppliers to delivery the 
  modernization project of upgrading both Database from flat files to ORACLE and old legacy code 
  to modernize into latest C++ and Linux operating system.`;

  TechnologiesUsed = "Technologies Used";
}
