import * as React from "react";
import { BaseComponent } from "../base/BaseComponent";

export class AboutComponent extends BaseComponent {
  render() {
    return (
      <section className="about-sec">
        <div className="container">
          <div className="hea-sec">
            <h1 className="head-sec">
              {this.lang.MeetFounderAndCEO}: <b>{this.lang.FahadSheikh}</b>
            </h1>
            <p>
              {this.lang.SerialEnterpreneurPara}
              <br />
              {this.lang.Blockchain +
                ", " +
                this.lang.CPP +
                ", " +
                this.lang.Linux +
                ", " +
                this.lang.NodeJS +
                " & " +
                this.lang.ReactJS}
            </p>
          </div>
          <div className="vide-sec">
            <div className="center">
              <video width="900" controls>
                <source src="assets/images/FahadSheikh.mp4" type="video/mp4" />
                <source src="assets/images/FahadSheikh.ogg" type="video/ogg" />
              </video>
            </div>
            <p style={{ marginBottom: "40px" }}>
              {this.lang.InnovateSolutionsPara}
            </p>
            <ul className="about-list">
              <li style={{ color: "black" }}>
                <div className="center">
                  15 <i>+ {this.lang.Years} </i>
                </div>
                <i>{this.lang.HighlyExperienced} </i>
              </li>
              <li style={{ color: "black" }}>
                <div className="center">
                  <img src="assets/images/check-black.png" alt="" />
                </div>
                <i>{this.lang.ReliableAndTrustable}</i>
              </li>
              <li style={{ color: "black" }}>
                <div className="center">3+</div>
                <i>{this.lang.SuccessfulCooperation}</i>
              </li>
            </ul>
          </div>
        </div>
      </section>
    );
  }

  constructor(props) {
    super(props);
    this.init();
  }

  init() {}
}
