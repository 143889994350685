import * as React from "react";
import { Route, Switch } from "react-router";
import { StaticHelper } from "../../../shared/static-helper";
import { Constants, StaticConstatns } from "../../../shared/constants";
import FragmentSupportingSwitch from "./FragmentSupportingSwitch";
import NoMatchComponent from "./NoMatchComponent";
import { HomeComponent } from "../home/HomeComponent";

const getRoutesForLangKey = (langKey, props) => {
  return (
    <React.Fragment key={langKey}>
      <Route
        key={langKey + StaticConstatns.RoutePaths.Home}
        exact
        path={"/" + langKey + StaticConstatns.RoutePaths.Home}
        render={() => <HomeComponent {...props} />}
      />
    </React.Fragment>
  );
};

const AppRoutes = props => {
  let langKeys = StaticHelper.objectToValuesArray(
    Constants.Instance.LanguageKey
  );
  return (
    <FragmentSupportingSwitch>
      {langKeys.map((k, i) => {
        return getRoutesForLangKey(k, props);
      })}
      <Route path="*" render={() => <NoMatchComponent {...props} />} />
    </FragmentSupportingSwitch>
  );
};
export default AppRoutes;
