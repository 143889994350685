import * as React from "react";
import { BaseComponent } from "../base/BaseComponent";

export class FooterComponent extends BaseComponent {
  render() {
    return (
      <footer>
        <div className="container">
          <div className="row">
            <div className="col-md-5">
              <ul className="fo-cont">
                <li>
                  <img src="assets/images/comp-1.png" alt="" /> UK Office : +44
                  203 620 7120{" "}
                </li>
                <li>
                  <img src="assets/images/comp-2.png" alt="" /> + 44 79 15 220
                  209
                </li>
                <li>
                  <img src="assets/images/comp-3.png" alt="" />{" "}
                  info@velocitysolutions.co.uk
                </li>
                <li>
                  <img src="assets/images/comp-4.png" alt="" /> 43 Bradenham
                  Road, UB4 8LR, Hayes, West London
                </li>
              </ul>
            </div>
            <div className="col-md-3">
              <div className="soci-fot">
                <a href="#">
                  <img src="assets/images/social-1.png" alt="" />
                </a>
                <a href="https://twitter.com/bit_velocity">
                  <img src="assets/images/social-2.png" alt="" />
                </a>
                <a href="www.linkedin.com/in/bitvelocity">
                  <img src="assets/images/social-3.png" alt="" />
                </a>
              </div>
            </div>
            <div className="col-md-4">
              <div className="fot-adres">
                {this.lang.CopyrightsWithYear}
                <br />
                {this.lang.AllRightsReserved}{" "}
                <a
                  href="#"
                  onClick={e => {
                    window.location.replace(
                      window.location.href.split("/")[0] +
                        "//" +
                        window.location.href.split("/")[2]
                    );
                  }}
                >
                  Velocity Solutions
                </a>{" "}
                <br />
                Velocity Solutions is the trading name for M&F Consulting Ltd{" "}
                with {this.lang.CompanyNo} : 08155251
                <br />
              </div>
            </div>
          </div>
        </div>
      </footer>
    );
  }

  constructor(props) {
    super(props);
    this.init();
  }

  init() {}
}
