import React from "react";
import { BaseComponent } from "../../base/BaseComponent";

export default class BigCard extends BaseComponent {
  render() {
    const {
      heading,
      para1,
      para2,
      para3,
      imgSrc,
      bgColor,
      clientSrc,
      key
    } = this.p;

    return (
      <section id={`work${key}`} className={bgColor}>
        <div className="container">
          <div className="row">
            <div className="industry-container">
              <h1 style={{ marginTop: "50px" }}>{heading}</h1>
              <hr className="heading-bottom-line text-center" />
              <div className="industry-sub-container">
                <div className="industry-sub-container content">
                  <p>{para1 ? para1 : this.props.children}</p>
                  <br />
                  <p>{para2}</p>
                  <br />
                  <p>{!para3 && para1 ? this.props.children : para3}</p>
                  <br />
                  <img src={clientSrc ? clientSrc : null} alt="" />
                </div>
                <img src={imgSrc} alt={heading} />
              </div>
              {para1 && para2 && para3 ? this.props.children : null}
            </div>
            <br />
            <br />
          </div>
        </div>
      </section>
    );
  }
}
