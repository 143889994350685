import React from "react";
import "./Logo.less";

const Logo = () => {
  return (
    <div className="logo">
      <img src="/assets/images/go-to.png" width={13} alt="" />
      Velocity
      <span>Solutions</span>
    </div>
  );
};

export default Logo;
