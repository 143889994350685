import * as React from "react";
import { BaseComponent } from "../app/components/base/BaseComponent";
import { connect } from "react-redux";
import { mdProps } from "../models/props";
import { getLanguage } from "../language/language";
import { Constants } from "../shared/constants";
import AppRoutes from "../app/components/base/routes";
import { Router } from "react-router-dom";
import history from "../shared/history";
import { StaticHelper } from "../shared/static-helper";
import { CookieHelper } from "../shared/cookie-heler";
import {
  updateGlobalInstance,
  updateGlobalProperty
} from "../store/actions/globals.actions";

class App extends BaseComponent {
  render() {
    this.initShorts();
    return this.isNullOrEmpty(this.g.langKey) ? null : (
      <Router history={history}>{AppRoutes(this.props)}</Router>
    );
    // <div className="main" id="body-top">
    //   <BannerComponent {...this.props}/>
    //   <BodyComponent {...this.props}/>
    //   <FooterComponent {...this.props}/>
    //   <a id="back2Top" title="Back to top" href="#"><img src="assets/images/go-to.png" alt="" /></a>
    // </div>
  }

  localProps: mdProps;
  langKeys = [];
  constructor(props: any) {
    super(props);
    this.state = {
      langKey: null
    };
    this.langKeys = StaticHelper.objectToValuesArray(
      Constants.Instance.LanguageKey
    );
    this.detectLanguage();
  }

  loadLanguage(langKey) {
    if (this.isNullOrEmpty(langKey)) {
      langKey = this.constants.DefaultLangKey;
    }
    this.lang = getLanguage(langKey);
    global.langKey = langKey;
    global.lang = this.lang;
  }

  detectLanguage() {
    let lkey: string;
    let loc = location.pathname.split("/");
    let langKeys = StaticHelper.objectToValuesArray(
      Constants.Instance.LanguageKey
    );
    if (loc.length > 1) {
      if (langKeys.indexOf(loc[1]) > -1) {
        lkey = loc[1];
      }
    }
    if (this.isNullOrEmpty(lkey)) {
      lkey = CookieHelper.get(this.constants.CookieKeys.LangKey);
    }
    if (this.isNullOrEmpty(lkey)) {
      lkey = this.constants.DefaultLangKey;
    }
    this.loadLanguage(lkey);
    this.updateState({ langKey: lkey });
    this.props.updateGlobalProperty(global.propKeys.langKey, lkey);
  }
}

const mapStateToProps = state => {
  return {
    globals: state.globals
  };
};

const mapDispatchToProps = dispatch => {
  return {
    updateGlobals: instance => {
      dispatch(updateGlobalInstance(instance));
    },
    updateGlobalProperty: (property, value) => {
      dispatch(updateGlobalProperty(property, value));
    }
  };
};

export default connect(
  mapStateToProps,
  mapDispatchToProps
)(App);
