import * as React from "react";
import { BaseComponent } from "../base/BaseComponent";
import { AboutComponent } from "./AboutComponent";
import { HowItWorksComponent } from "./HowItWorksComponent";
import { ServicesComponent } from "./ServicesComponent";
import { TechnologiesComponent } from "./TechnologiesComponent";
import { ExperienceComponent } from "./ExperienceComponent";
import { OurClientsComponent } from "./OurClientsCompoent";
import { ContactUsComponent } from "../contact-us/contact-us.component";
import { TeamComponent } from "./TeamComponent";
import { AffiliatedComponent } from "./AffiliatedComponent";
import { IndustriesComponent } from "./industries/IndustriesComponent";

export class BodyComponent extends BaseComponent {
  render() {
    return (
      <div>
        <AboutComponent {...this.props} />
        <HowItWorksComponent {...this.props} />
        <AffiliatedComponent {...this.props} />
        <ServicesComponent {...this.props} />
        <TechnologiesComponent {...this.props} />
        <ExperienceComponent {...this.props} />
        <IndustriesComponent {...this.props} />
        <TeamComponent {...this.props} />
        <OurClientsComponent {...this.props} />
        <ContactUsComponent {...this.props} />
      </div>
    );
  }

  constructor(props) {
    super(props);
    this.init();
  }

  init() {}
}
