import * as React from "react";
import { BaseComponent } from "../base/BaseComponent";

export class AffiliatedComponent extends BaseComponent {
  render() {
    return (
      <section className="exper-sec">
        <div className="container">
          <div className="row">
            <div className="col-lg-12">
              <div className="dmp-container">
                <p className="dmp-container para">Approved Supplier For</p>
                <a
                  href="https://www.digitalmarketplace.service.gov.uk/"
                  target="_blank"
                  rel="noopener"
                >
                  <img
                    src="assets/images/Digital-Marketplace-Logo.png"
                    alt="Digital Market Place"
                    width={512}
                  />
                </a>
              </div>
            </div>
          </div>
        </div>
      </section>
    );
  }

  constructor(props) {
    super(props);
    this.init();
  }

  init() {}
}
