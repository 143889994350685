import * as React from "react";
import { BaseComponent } from "../base/BaseComponent";
import { BannerComponent } from "./BannerComponent";
import { BodyComponent } from "./BodyComponent";
import { FooterComponent } from "./FooterComponent";

export class HomeComponent extends BaseComponent {
  render() {
    this.initShorts();
    return (
      <div className="main" id="body-top">
        <BannerComponent {...this.props} />
        <BodyComponent {...this.props} />
        <FooterComponent {...this.props} />
        <a id="back2Top" title="Back to top">
          <img src="assets/images/go-to.png" alt="" />
        </a>
      </div>
    );
  }

  componentDidMount = () => {
    this.addTawkChatWindow();
  };

  constructor(props: any) {
    super(props);
  }
}
