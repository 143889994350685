import * as React from "react";
import { BaseComponent } from "../base/BaseComponent";

export class TeamComponent extends BaseComponent {
  render() {
    return (
      <section className="bg-white">
        <div className="team-container text-center" id="team-info">
          <h1 style={{ marginTop: "50px" }}>
            {this.lang.Our} {this.lang.Team}
          </h1>
          <hr className="heading-bottom-line text-center" />
          <p>{this.lang.TeamPara}</p>

          <h1 style={{ marginBottom: "30px", marginTop: "30px" }}>
            {this.lang.Core}&nbsp;{this.lang.Team}
          </h1>

          <img
            src="/assets/images/fahad.webp"
            width={250}
            alt={this.lang.FahadSheikh}
          />
          <h4>{this.lang.FahadSheikh}</h4>
          <h5>
            {this.lang.Founder} &#38; {this.lang.CEO}
          </h5>
          <p>{this.lang.CEOPara}</p>
          <br />
          <br />
          <img
            src="/assets/images/watkins.webp"
            width={250}
            alt={this.lang.Watkin}
          />
          <h4>
            {this.lang.David}&nbsp;{this.lang.Watkin}
          </h4>
          <h5>
            {this.lang.Team}&nbsp;
            {this.lang.Lead}&nbsp;
          </h5>
          <p>{this.lang.WatkinsPara}</p>
          <br />

          <br />
          <hr className="heading-bottom-line text-center" />
          <h3>
            {this.lang.Development}&nbsp;{this.lang.Team}
          </h3>
          <div className="dev-container text-center">
            <figure>
              <img
                src="/assets/images/jamil.webp"
                width={200}
                alt={this.lang.Jamil}
              />
              <h4>{this.lang.Jamil}</h4>
              <figcaption style={{ width: "unset" }}>
                {this.lang.TeamDescription3}
              </figcaption>
            </figure>
          </div>
          <div className="dev-container text-center">
            <figure className="dev-sub-container">
              <img
                src="/assets/images/inaam.webp"
                width={200}
                alt={this.lang.Inaam}
                id="inaam"
              />
              <h4>{this.lang.Inaam}</h4>
              <figcaption>{this.lang.TeamDescription1}</figcaption>
            </figure>
            <figure>
              <img
                src="/assets/images/shoaib.webp"
                width={200}
                alt={this.lang.Shoaib}
              />
              <h4>{this.lang.Shoaib}</h4>
              <figcaption>{this.lang.TeamDescription2}</figcaption>
            </figure>
          </div>

          <br />
          <br />
        </div>
      </section>
    );
  }
}
