import * as React from "react";
import { BaseComponent } from "../base/BaseComponent";
import { mdContactUs, ContactUsMetaData } from "../../../models/contact-us";
import { mdFormControl } from "../../../shared/form-control";
import * as ValidationAttributes from "../../../shared/validation-attributes";
import { StaticHelper } from "../../../shared/static-helper";
import { mdCallResponse } from "../../../models/call-response";
import { Transitions, TransitionState } from "../../../models/transitions";

export class ContactUsComponent extends BaseComponent {
  render() {
    return (
      <section className="query-form-sec main-scroll">
        <div className="container">
          <div className="row">
            <div className="col-lg-12">
              <div className="contact-us-content" id="contact-us">
                {" "}
                <h1 className="we-believe-text">{this.lang.ContactUs1}</h1>
                <h1 className="qualities-text">{this.lang.ContactUs2}</h1>
                <h1 className="we-believe-text">{this.lang.ContactUs3}</h1>
                <br />
                <br />
                <p className="contact-us-text">
                  {this.lang.ContactUs4} <br />
                  {this.lang.ContactUs5}
                  <img src="assets/images/green-arrow.png" alt="" />
                </p>
                <div>
                  {this.animatedCSSDivWithAttr(
                    <p className="thanksText">
                      {this.lang.ThankYouForContactingUs +
                        " " +
                        this.lang.WeHaveReceivedYourMessage}
                      <br />
                      {this.lang.OurExpertWillBeInTouchWithYou}
                    </p>,
                    {
                      id: "left",
                      className: `row slideDiv inline-div`,
                      style: {
                        backgroundColor: "rgb(54, 173, 102)",
                        fontSize: "25px",
                        fontWeight: 200
                      }
                    },
                    this.state.animValues.animateDiv
                  )}

                  {this.animatedCSSDivWithAttr(
                    <form id="contact-us-form">
                      <div className="inq-form">
                        <div className="row">
                          <div className="col-md-4 col-sm-6">
                            <div className="inp-sec">
                              {" "}
                              <img src="assets/images/input-1.png" alt="" />
                              <input
                                type="text"
                                placeholder={this.lang.Name}
                                className="contactUsField"
                                id="name"
                                onInput={e => {
                                  this.handleFormControlInput(
                                    this.state.form.name.name,
                                    e
                                  );
                                }}
                              />
                            </div>
                          </div>
                          <div className="col-md-4 col-sm-6">
                            <div className="inp-sec">
                              {" "}
                              <img src="assets/images/input-2.png" alt="" />
                              <input
                                type="text"
                                placeholder={this.lang.Email}
                                id="email"
                                className="contactUsField"
                                onInput={e => {
                                  this.handleFormControlInput(
                                    this.state.form.email.name,
                                    e
                                  );
                                }}
                              />
                            </div>
                          </div>
                          <div className="col-md-4 col-sm-6">
                            <div className="inp-sec">
                              {" "}
                              <img src="assets/images/input-3.png" alt="" />
                              <input
                                type="text"
                                id="contactNo"
                                placeholder={this.lang.ContactNo}
                                className="contactUsField"
                                onInput={e => {
                                  this.handleFormControlInput(
                                    this.state.form.contact_no.name,
                                    e
                                  );
                                }}
                              />
                            </div>
                          </div>
                          {/* <div className="col-md-3 col-sm-6">
                            <div className="inp-sec">
                              {" "}
                              <img src="assets/images/input-4.png" alt="" />
                              <select
                                onChange={e => {
                                  this.handleFormControlInput(
                                    this.state.form.interested_in.name,
                                    e
                                  );
                                }}
                                className="contactUsField"
                              >
                                {this.technologies.map((m, i) => {
                                  return (
                                    <option
                                      key={i}
                                      className={`${i == 0 ? "hidden" : ""}`}
                                      value={m}
                                    >
                                      {m}
                                    </option>
                                  );
                                })}
                              </select>
                            </div>
                          </div> */}
                          {/* <div className="col-md-3 col-sm-6">
                            <div className="inp-sec">
                              <img
                                style={{ width: "42px", height: "41px" }}
                                src="assets/images/clock-circular-outline.png"
                                alt=""
                              />
                              <select
                                onChange={e => {
                                  this.handleFormControlInput(
                                    this.state.form.duration.name,
                                    e
                                  );
                                }}
                                className="contactUsField"
                              >
                                {this.durations.map((m, i) => {
                                  return (
                                    <option
                                      key={i}
                                      className={`${i == 0 ? "hidden" : ""}`}
                                      value={m}
                                    >
                                      {m}
                                    </option>
                                  );
                                })}
                              </select>
                            </div>
                          </div> */}
                          {/* <div className="col-md-3 col-sm-6">
                            <div className="inp-sec">
                              {" "}
                              <img src="assets/images/input-5.png" alt="" />
                              <select
                                onChange={e => {
                                  this.handleFormControlInput(
                                    this.state.form.budget.name,
                                    e
                                  );
                                }}
                                className="contactUsField"
                              >
                                {this.budgetArray.map((m, i) => {
                                  return (
                                    <option
                                      key={i}
                                      className={`${i == 0 ? "hidden" : ""}`}
                                      value={m}
                                    >
                                      {m}
                                    </option>
                                  );
                                })}
                              </select>
                            </div>
                          </div> */}
                          {/* <div className="col-md-3 col-sm-6">
                            <div className="inp-sec">
                              {" "}
                              <img src="assets/images/input-6.png" alt="" />
                              <input
                                type="text"
                                placeholder={this.lang.SkypeId}
                                className="contactUsField"
                                onInput={e => {
                                  this.handleFormControlInput(
                                    this.state.form.skype_id.name,
                                    e
                                  );
                                }}
                              />
                            </div>
                          </div> */}
                          <div className="col-sm-12">
                            <div className="inp-sec">
                              {" "}
                              <img src="assets/images/input-2.png" alt="" />
                              <input
                                type="text"
                                placeholder={this.lang.Message}
                                id="message"
                                className="contactUsField"
                                onInput={e => {
                                  this.handleFormControlInput(
                                    this.state.form.message.name,
                                    e
                                  );
                                }}
                              />
                            </div>
                          </div>
                          <div className="col-sm-12">
                            <strong>
                              {this.state.showSubmitResponse ? (
                                <div
                                  className={`${this.state.submitResponseClass} text-bold`}
                                >
                                  {this.state.submitResponse}
                                </div>
                              ) : null}
                            </strong>
                          </div>
                        </div>
                      </div>
                      <div className="dis-cent">
                        <button
                          type="button"
                          disabled={this.state.diableSubmitButton}
                          onClick={this.onSubmit}
                          id="submitBtn"
                          className="contactUsField"
                        >
                          Contact Our Experts
                        </button>
                      </div>
                    </form>,
                    {
                      id: "right"
                    },
                    this.state.animValues.formDiv
                  )}
                </div>
              </div>
            </div>
          </div>
        </div>
      </section>
    );
  }

  model: mdContactUs;
  durations: string[] = [
    "Contract",
    "Hourly",
    "Daily",
    "Monthly",
    "Quaterly",
    "Yearly"
  ];
  technologies: string[] = [
    "Interested In",
    "Angular js",
    "Node js",
    "React js",
    "C++",
    "AWS",
    "EC2 Console",
    "ERC 20 Tokens",
    "ASP .NET MVC",
    "JAVA",
    "Android",
    "Blockchain",
    "Xen Server",
    "Cloud Vertualization",
    "IOS"
  ];
  budgetArray: string[] = [
    "Budget",
    "Less Than £25k",
    "£25-50k",
    "£100-250k",
    "£250-500k",
    "£1 Million",
    "More Than 1 £Million"
  ];
  constructor(props) {
    super(props);
    this.init();
  }

  init() {
    this.model = new mdContactUs(true);
    this.model.duration = this.durations[0];
    this.model.interested_in = this.technologies[0];
    this.model.budget = this.budgetArray[0];

    this.state = {
      form: {
        name: new mdFormControl(this.model.name, "name", this.lang.Name, [
          // new ValidationAttributes.MaxLengthValidator(this.lang.MaxLengthFormat2,
          //     ContactUsMetaData.nameMaxLength),
        ]),
        message: new mdFormControl(
          this.model.message,
          "message",
          this.lang.Message,
          [
            // new ValidationAttributes.MaxLengthValidator(this.lang.MaxLengthFormat2,
            //     ContactUsMetaData.nameMaxLength),
          ]
        ),
        contact_no: new mdFormControl(
          this.model.contact_no,
          "contact_no",
          this.lang.ContactNo,
          [
            // new ValidationAttributes.MaxLengthValidator(this.lang.MaxLengthFormat2,
            //     ContactUsMetaData.contactNoMaxLength),
          ]
        ),
        email: new mdFormControl(this.model.email, "email", this.lang.Email, [
          new ValidationAttributes.RequiredValidator(this.lang.RequiredFormat),
          new ValidationAttributes.MailValidator(this.lang.InvalidEmail),
          new ValidationAttributes.MaxLengthValidator(
            this.lang.MaxLengthFormat2,
            ContactUsMetaData.emailMaxLength
          )
        ]),
        interested_in: new mdFormControl(
          this.model.interested_in,
          "interested_in",
          this.lang.InterestedIn
        ),
        duration: new mdFormControl(
          this.model.duration,
          "duration",
          this.lang.Duration
        ),
        budget: new mdFormControl(
          this.model.budget,
          "budget",
          this.lang.Budget
        ),
        skype_id: new mdFormControl(
          this.model.skype_id,
          "skype_id",
          this.lang.SkypeId,
          [
            // new ValidationAttributes.MaxLengthValidator(this.lang.MaxLengthFormat2,
            //     ContactUsMetaData.skypeIdMaxLength),
          ]
        )
      },
      diableSubmitButton: false,
      showSubmitResponse: false,
      submitResponseClass: "",
      submitResponse: "",
      animValues: {
        animateDiv: new mdFormControl(
          this.getTransisition(Transitions.fadeInUp2s),
          "animateDiv"
        ),
        formDiv: new mdFormControl(
          this.getTransisition(Transitions.flipOutX2s),
          "formDiv"
        )
      }
    };
  }

  onScriptLoad() {
    this.log.debug("Google reCAPTCHA loaded and is ready for use!");
  }

  onScriptError() {
    this.log.error("Something went long when loading the Google reCAPTCHA");
  }

  onSubmit = e => {
    e.preventDefault();
    this.showErrors = true;
    if (!this.validateForm("form")) {
      let error = this.validationErrorsArray(this.state.form).join("\n");
      alert(error);
      return;
    }

    let formData: mdContactUs = this.getFormData(
      this.state.form
    ) as mdContactUs;
    if (this.budgetArray.indexOf(formData.budget) < 1) {
      formData.budget = "";
    }
    if (this.technologies.indexOf(formData.interested_in) < 1) {
      formData.interested_in = "";
    }
    if (this.durations.indexOf(formData.duration) < 1) {
      formData.duration = "";
    }
    formData.uuid = StaticHelper.getUUID();
    this.log.debug(formData);

    //everything is fine, now save the data
    this.updateState({
      diableSubmitButton: true
    });

    //var response = this.http.post(this.http.endpoints.postContactUs, formData);
    // this.spinner.show();
    this.http
      .post<mdCallResponse>(this.constants.EndPoints.PostContactUs, formData)
      .then((res: mdCallResponse) => {
        // this.spinner.hide();

        let submitRequestResposneClass = "text-danger";
        let submitResponseMessage;
        if (res) {
          submitResponseMessage = res.message.replace("\n", "<br/>");
          if (res.isSuccess) {
            submitRequestResposneClass = "text-success";
          }
        }

        // this.showsubmitResponse = true;
        if (res) {
          if (res.isSuccess) {
            this.updateAnimValue(
              "animateDiv",
              Transitions.fadeInUp2s,
              TransitionState.Running
            );
            this.updateAnimValue(
              "formDiv",
              Transitions.flipOutX2s,
              TransitionState.Running
            );
          }
        }
        this.updateState(
          {
            diableSubmitButton: false,
            submitResponseClass: submitRequestResposneClass,
            submitResponse: submitResponseMessage
          },
          () => {
            this.log.debug(this.state.animValues);
          }
        );
      })
      .catch(error => {
        // this.spinner.hide();
        this.updateState({
          diableSubmitButton: false
        });
      });
  };
}
