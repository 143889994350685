import * as React from "react";
import { BaseComponent } from "../base/BaseComponent";
import Slider from "react-slick";

export class OurClientsComponent extends BaseComponent {
  render() {
    let settings = {
      // className: "clien-log-si",
      dots: false,
      infinite: true,
      arrows: false,
      autoplay: true,
      autoplaySpeed: 2000,
      speed: 300,
      slidesToShow: 5,
      slidesToScroll: 1,
      responsive: [
        {
          breakpoint: 1280,
          settings: {
            slidesToShow: 3,
            slidesToScroll: 3,
            infinite: true
          }
        },
        {
          breakpoint: 600,
          settings: {
            slidesToShow: 2,
            slidesToScroll: 2
          }
        },
        {
          breakpoint: 480,
          settings: {
            slidesToShow: 1,
            slidesToScroll: 1
          }
        }
        // You can unslick at a given breakpoint now by adding:
        // settings: "unslick"
        // instead of a settings object
      ]
    };
    return (
      <section className="clients-sec">
        <div className="container">
          <div className="row">
            <div className="col-lg-12">
              <h1 style={{ fontWeight: "lighter" }}>
                {this.lang.ProjectsSuccessfullyDelivery1}
                <br />
                {this.lang.ProjectsSuccessfullyDelivery2}
              </h1>
              <br />
              <br />
              {/* <div className="clien-slider slider"> */}
              <Slider {...settings}>
                {this.slides.map((m, i) => {
                  return (
                    <div key={i} className="clien-log-si">
                      <div className="center">
                        <img src={m.image} alt="" />
                      </div>
                      {/* {m.title} */}
                    </div>
                  );
                })}
              </Slider>
              {/* </div> */}
            </div>
          </div>
          <div className="lets-asksec">
            {" "}
            <i>{this.lang.Lets.toUpperCase()}</i>{" "}
            <img src="assets/images/stand-man.png" alt="" />{" "}
            <i>{this.lang.Talk.toUpperCase()}</i>
          </div>
        </div>
      </section>
    );
  }

  slides: any[];
  constructor(props) {
    super(props);
    this.init();
  }

  init() {
    this.slides = [
      {
        image: "assets/images/client-1.png",
        title: (
          <b>
            {this.lang.DepartureControlSystem1}
            <br />
            {this.lang.DepartureControlSystem2}
          </b>
        )
      },
      {
        image: "assets/images/client-2.png",
        title: (
          <b>
            {this.lang.RealtimeRemoteControl1}
            <br />
            {this.lang.RealtimeRemoteControl2}
          </b>
        )
      },
      {
        image: "assets/images/transport-for-london.svg",
        title: (
          <b>
            {this.lang.LondonUndergroundRailSimulation1}
            <br />
            {this.lang.DepartureControlSystem2}
          </b>
        )
      },
      {
        image: "assets/images/client-4.png",
        title: <b>{this.lang.CloudGaming}</b>
      },
      {
        image: "assets/images/client-5.png",
        title: <b>{this.lang.CloudVirtualization}</b>
      },
      {
        image: "assets/images/bethesda.png",
        title: <b>{this.lang.CloudVirtualization}</b>
      },
      {
        image: "assets/images/nhs.svg",
        title: <b>{this.lang.CloudVirtualization}</b>
      }
    ];
    this.slides = [...this.slides, ...this.slides];
  }
}
