import * as React from "react";
import { BaseComponent } from "../../base/BaseComponent";

export class IndustriesGridComponent extends BaseComponent {
  render() {
    return (
      <section className="our-soft-tech-sec slider-headersec">
        <div className="container">
          <div className="row">
            <div>
              <h1 style={{ color: "white", padding: "0 9px" }}>
                {this.lang.OurSoftwareDevelopmentIndustries}
              </h1>
              <p>{this.lang.OurSoftwareDevelopmentIndustriesPara}</p>
            </div>
            <div className="col-md-12">
              <div className="tab-mnus">
                <ul className="nav nav-tabs tab-des">
                  {this.topics.map((t, i) => {
                    return (
                      <li
                        key={i}
                        // className={i == 0 ? "active" : ""}
                      >
                        <a href={"#work" + i}>
                          <div className="div-pre-se">
                            <div className="center">
                              <img src={t.image} alt="" />
                            </div>
                            {t.title}
                          </div>
                        </a>
                      </li>
                    );
                  })}
                </ul>
              </div>
            </div>
            {/* <div className="col-md-6">
              <div className="tab-content">
                {this.topics.map((t, i) => {
                  return (
                    <div
                      key={i}
                      id={"work" + i}
                      className={`tab-pane fade in ${i == 0 ? "active" : ""}`}
                    >
                      <div className="act-tab-con">
                        <div className="center">
                          <img src={t.detailImage} alt="" />
                        </div>
                        <b>{t.titleText}</b>
                        <p>{t.para}</p>
                      </div>
                    </div>
                  );
                })}
              </div>
            </div>
           */}
          </div>
        </div>
      </section>
    );
  }

  topics: any[];
  constructor(props) {
    super(props);
    this.init();
  }

  init() {
    this.topics = [
      {
        image: "assets/images/ind-1.png",
        detailImage: "assets/images/act-ind-1.png",
        title: <i>{this.lang.Blockchain}</i>,
        titleText: this.lang.Blockchain,
        para: (
          <ul>
            <li>{this.lang.BlockchainPara1}</li>
            <li>{this.lang.BlockchainPara2}</li>
            <li>{this.lang.BlockchainPara3}</li>
          </ul>
        )
      },
      {
        image: "assets/images/ind-2.png",
        detailImage: "assets/images/act-ind-2.png",
        title: <i>{this.lang.Healthcare}</i>,
        titleText: this.lang.Healthcare,
        para: this.lang.HealthcarePara
      },
      {
        image: "assets/images/ind-3.png",
        detailImage: "assets/images/act-ind-3.png",
        title: <i>{this.lang.BankingAndFinance}</i>,
        titleText: this.lang.BankingAndFinance,
        para: this.lang.BankingAndFinancePara
      },
      {
        image: "assets/images/ind-4.png",
        detailImage: "assets/images/act-ind-4.png",
        title: (
          <i>
            {this.lang.RealTimeTradingApps1}
            <br />
            {this.lang.RealTimeTradingApps2}
          </i>
        ),
        titleText:
          this.lang.RealTimeTradingApps1 + " " + this.lang.RealTimeTradingApps2,
        para: this.lang.RealTimeTradingAppsPara
      },
      {
        image: "assets/images/ind-5.png",
        detailImage: "assets/images/act-ind-5.png",
        title: (
          <i>
            {this.lang.EducationAndELearning1}
            <br />
            {this.lang.ELearning}
          </i>
        ),
        titleText: this.lang.EducationAndELearning1 + " " + this.lang.ELearning,
        para: this.lang.EducationAndELearningPara
      },
      {
        image: "assets/images/ind-6.png",
        detailImage: "assets/images/act-ind-6.png",
        title: (
          <i>
            {this.lang.Cloud}
            <br />
            {this.lang.Technologies}
          </i>
        ),
        titleText: this.lang.Cloud + " " + this.lang.Technologies,
        para: this.lang.CloudTechnologiesPara
      },
      {
        image: "assets/images/ind-7.png",
        detailImage: "assets/images/act-ind-7.png",
        title: <i>{this.lang.Automotive}</i>,
        titleText: this.lang.Automotive,
        para: this.lang.AutomotivePara
      },
      {
        image: "assets/images/ind-8.png",
        detailImage: "assets/images/act-ind-8.png",
        title: <i>{this.lang.TravelAndTourism}</i>,
        titleText: this.lang.TravelAndTourism,
        para: this.lang.TravelAndTourismPara
      },
      {
        image: "assets/images/ind-9.png",
        detailImage: "assets/images/act-ind-9.png",
        title: <i>{this.lang.Manufacturing}</i>,
        titleText: this.lang.Manufacturing,
        para: this.lang.ManufacturingPara
      },
      {
        image: "assets/images/ind-10.png",
        detailImage: "assets/images/act-ind-10.png",
        title: (
          <i>
            {this.lang.Intellectual}
            <br />
            {this.lang.PropertyProducts}
          </i>
        ),
        titleText: this.lang.Intellectual + " " + this.lang.PropertyProducts,
        para: this.lang.IntellectualPropertyProductsPara
      },
      {
        image: "assets/images/ind-11.png",
        detailImage: "assets/images/act-ind-11.png",
        title: <i>{this.lang.Gaming}</i>,
        titleText: this.lang.Gaming,
        para: this.lang.GamingPara
      },
      {
        image: "assets/images/ind-12.png",
        detailImage: "assets/images/act-ind-12.png",
        title: (
          <i>
            {this.lang.MediaAnd}
            <br />
            {this.lang.Entertainment}
          </i>
        ),
        titleText: this.lang.MediaAnd + " " + this.lang.Entertainment,
        para: this.lang.MediaAndEntertainmentPara
      }
    ];
  }
}
