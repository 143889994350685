import * as React from "react";
import { BaseComponent } from "../../base/BaseComponent";

export class BlockchainTechnologies extends BaseComponent {
  render() {
    return (
      <>
        <h1>{this.lang.TechnologiesWeSpecializeIn}</h1>
        <hr className="heading-bottom-line text-center" />
        <ul
          className="blockchain-tech"
          style={{ display: "flex", justifyContent: "center" }}
        >
          <li>
            <figure className="center">
              <img src="assets/images/work-7.png" alt="" />
              <figcaption>{this.lang.ERC20Tokens}</figcaption>
            </figure>
          </li>
          <li>
            <figure className="center">
              <img src="assets/images/chip.svg" width={110} alt="" />
              <figcaption>{this.lang.SecurityTokens}</figcaption>
            </figure>
          </li>

          <li>
            <figure className="center">
              <img src="assets/images/exchange.svg" width={110} alt="" />
              <figcaption>{this.lang.DigitalAssetExchange}</figcaption>
            </figure>
          </li>
          <li>
            <figure className="center">
              <img src="assets/images/work-6.png" alt="" />
              <figcaption>{this.lang.Solidity}</figcaption>
            </figure>
          </li>
        </ul>
      </>
    );
  }
}
