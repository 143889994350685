import * as React from "react";
import { BaseComponent } from "../base/BaseComponent";
import LanguageMenu from "../../../containers/LanguageMenu";
import { Popover } from "antd";
import Logo from "../shared/Logo/Logo";
import { Link } from "react-router-dom";
import { Modal } from "antd";
import { ContactUsForm } from "./ContactUsForm";

export class BannerComponent extends BaseComponent {
  render() {
    let lmnu = LanguageMenu(this.props);

    return (
      <div className="slider-headersec">
        {/* <Modal
          title={
            this.lang.ContactUs + " To Get " + this.lang.FreeInitialConsultation
          }
          visible={this.state.showModal}
          footer={null}
          destroyOnClose={true}
          maskClosable={false}
          width={768}
          onCancel={_ => {
            this.updateState({ showModal: false });
          }}
          style={{ transform: "translateY(-10%)" }}
        >
          <ContactUsForm />
        </Modal>
        
         */}
        <header>
          <div className="container-fluid">
            <div className="logo-left">
              <Link to="/">
                <Logo />
              </Link>
            </div>
            <div className="pull-right comp-adress">
              <div className="cladre-con">
                {" "}
                <span>
                  <img src="assets/images/comp-3.png" alt="" />{" "}
                  info@velocitysolutions.co.uk
                </span>{" "}
                <span>
                  <img src="assets/images/cal-2.png" alt="" /> + 44 79 15 220
                  209
                </span>
                <span style={{ marginBottom: "20px" }}>
                  <Popover
                    placement="bottomRight"
                    content={lmnu.menu}
                    trigger="hover"
                  >
                    <span style={{ cursor: "pointer", color: "#f5665c" }}>
                      <img
                        src={
                          "/assets/images/countries/" +
                          lmnu.current.icon +
                          ".svg"
                        }
                        width={50}
                        height={25}
                        style={{
                          cursor: "pointer",
                          marginTop: "5px",
                          marginLeft: "5px"
                        }}
                        alt="Country Flag"
                      />
                    </span>
                  </Popover>
                </span>
              </div>

              {/* <a href="#contact-us">{this.lang.FreeInitialConsultation}</a> */}
            </div>
          </div>
        </header>
        {/* <br /> */}
        <div className="slider-image" style={{ marginTop: "15px" }}>
          <div className="head-conts text-medium">
            <p>
              {this.lang.AreYouLookingForAReliable}
              <br />
              {this.lang.SolutionProviderThatCanDeliverInTime}
            </p>
            <ul>
              <li> 100% {this.lang.Transparency.toUpperCase()}</li>
              <li>
                <img src="assets/images/header-ofer-1.png" alt="" />{" "}
                {this.lang.QualityDeliverables.toUpperCase()}
              </li>
              <li>
                <img src="assets/images/header-ofer-2.png" alt="" />{" "}
                {this.lang.FlexibleEngagementModels.toUpperCase()}
              </li>
              <li>
                <img src="assets/images/header-ofer-3.png" alt="" />{" "}
                {this.lang.SkilledTeam.toUpperCase()}
              </li>
            </ul>

            <a href="#contact-us" className="team-btn">
              {this.lang.TalkToOurExperts.toUpperCase()}
            </a>
            <a href="#team-info" className="team-btn">
              {this.lang.OurTeam.toUpperCase()}
            </a>
            <a
              href="#contact-us"
              className="team-btn"
              style={{ marginBottom: "45px" }}
            >
              {this.lang.FreeInitialConsultation}
            </a>

            {/* <p className="consult-link">
              <a href="#contact-us">{this.lang.FreeInitialConsultation}</a>
            </p> */}
          </div>
          <div
            className="sldi-image-fs"
            style={{ backgroundImage: "url(assets/images/dev.webp)" }}
          />
        </div>
      </div>
    );
  }

  constructor(props) {
    super(props);
    this.init();
  }

  init() {
    this.updateState({ showModal: true });
  }
}
