
    export enum RecordStatus {
        active = 1,
        deleted = 2,
        pendingVerification = 3,
    }
    export enum Messages{
        TaskExecuted = "TaskExecuted",
        QueueProcessStarted = "QueueProcessStarted",
    }
    export enum SpinnerSize{
        small = "small",
        default = "default",
        medium = "medium",
        large = "large",
    }