import * as React from "react";
import { BaseComponent } from "../base/BaseComponent";

export class ServicesComponent extends BaseComponent {
  render() {
    return (
      <section className="servide-sec">
        <div className="container-fluid">
          <div className="row">
            <div className="col-md-6">
              <div
                className="serv-img"
                style={{
                  backgroundImage: "url(assets/images/services-image.jpg)"
                }}
              >
                <b>{this.lang.ServicesWeDeliver}</b>
                <p>{this.lang.ServicesWeDeliverPara}</p>
              </div>
            </div>
            <div className="col-md-6">
              <div id="f1_container">
                <div id="f1_card" className="shadow">
                  <div className="front face">
                    <div className="center">
                      <img src="assets/images/servid-1.png" alt="" />
                    </div>
                    <b>{this.lang.TechnicalConsulting}</b>{" "}
                    <span className="">
                      {" "}
                      <i>{this.lang.AngularJS}</i> | <i>{this.lang.NodeJS}</i> |
                      <i>{this.lang.MeanStack}</i> |{" "}
                      <i>{this.lang.ReactNative}</i> |{" "}
                    </span>
                  </div>
                  <div className="back face center">
                    <div className="flip-text">
                      {" "}
                      <b>{this.lang.TechnicalConsulting}</b>
                      <p>{this.lang.TechnicalConsultingPara}</p>
                    </div>
                  </div>
                </div>
              </div>
              <div id="f1_container">
                <div id="f1_card" className="shadow">
                  <div className="front face">
                    <div className="center">
                      <img src="assets/images/servid-2.png" alt="" />
                    </div>
                    <b>{this.lang.ProductDevelopment}</b>{" "}
                    <span>
                      {" "}
                      <i>{this.lang.BitVelocity}</i> |{" "}
                      <i>{this.lang.BlockchainForTravel}</i>
                      | <br />
                      <i>{this.lang.BlockchainForRealEstate}</i> | <br />
                      <i>{this.lang.CloudGaming}</i> |{" "}
                      <i>{this.lang.CloudVirtualization}</i>{" "}
                    </span>
                  </div>
                  <div className="back face center">
                    <div className="flip-text">
                      {" "}
                      <b>{this.lang.ProductDevelopment}</b>
                      <p>{this.lang.ProductDevelopmentPara}</p>
                    </div>
                  </div>
                </div>
              </div>
              <div id="f1_container">
                <div id="f1_card" className="shadow">
                  <div className="front face">
                    <div className="center">
                      <img src="assets/images/servid-3.png" alt="" />
                    </div>
                    <b>{this.lang.UIAndUX}</b>{" "}
                    <span>
                      {" "}
                      <i>{this.lang.HTML5}</i> I <i>{this.lang.CSS}</i> I{" "}
                      <i>{this.lang.ReactJS}</i> I <i>{this.lang.AngularJS}</i>
                    </span>
                  </div>
                  <div className="back face center">
                    <div className="flip-text">
                      {" "}
                      <b>{this.lang.UIAndUX}</b>
                      <p>{this.lang.UIAndUXPara}</p>
                    </div>
                  </div>
                </div>
              </div>
              <div id="f1_container">
                <div id="f1_card" className="shadow">
                  <div className="front face">
                    <div className="center">
                      <img src="assets/images/servid-4.png" alt="" />
                    </div>
                    <b>{this.lang.DevOps}</b>{" "}
                    <span>
                      {" "}
                      <i>{this.lang.FullStackDevelopment}</i> |{" "}
                      <i>{this.lang.MobileDevelopment}</i>|
                      <i>{this.lang.ServerDevelopment}</i>{" "}
                    </span>
                  </div>
                  <div className="back face center">
                    <div className="flip-text">
                      {" "}
                      <b>{this.lang.DevOps}</b>
                      <p>{this.lang.DevOpsPara}</p>
                    </div>
                  </div>
                </div>
              </div>
            </div>
          </div>
        </div>
      </section>
    );
  }

  constructor(props) {
    super(props);
    this.init();
  }

  init() {}
}
