import * as React from "react";
import { BaseComponent } from "../base/BaseComponent";

export class HowItWorksComponent extends BaseComponent {
  render() {
    return (
      <section className="how-ti-work-sec">
        <div className="container hiw-container">
          <h1
            style={{
              marginTop: "50px",
              marginBottom: "50px",
              fontWeight: "normal"
            }}
          >
            {this.lang.HowItWorks}
          </h1>
          <ul className="hiw-list">
            <li className="work-sec">
              <div className="center">1</div>
              <b>
                {this.lang.TalkToOneofOurPara} <br />
                {this.lang.IndustryExpertsPara}
              </b>
              <p>
                {this.lang.AVelocityDirectorPara} <br />
                {this.lang.AVelocityDirectorPara2} <br />
                {this.lang.AVelocityDirectorPara3}
              </p>
            </li>
            <li className="work-sec">
              <div className="center">2</div>
              <b>{this.lang.AllocateSuitableResource}</b>
              <p>{this.lang.AllocatedResourcesInitiates}</p>
            </li>
            <li className="work-sec">
              <div className="center">3</div>
              <b>{this.lang.EnsuringPromptDelivery}</b>
              <p>
                {this.lang.EnsuringPromptDeliveryPara1} <br />
                {this.lang.EnsuringPromptDeliveryPara2}
                <br />
                {this.lang.EnsuringPromptDeliveryPara3}
              </p>
            </li>
          </ul>
        </div>
      </section>
    );
  }

  constructor(props) {
    super(props);
    this.init();
  }

  init() {}
}
